import {cloneDeep} from 'lodash'
/**
 * @param array => The array to remove the object from. The original array is not modified by this method.
 * @param object => The object to remove
 * @param uniqueProp => A unique property e.g. 'id' that can be used to find the object more efficiently especially in arrays containing large objects
 * @return returns If object found, returns a new array without the removed object
 */
export const removeObjectFromArray = (array: Array<any>, object: any, uniqueProp?: string): Array<any> => {
	const index = findObjectIndex(array, object, uniqueProp);
	if (index === -1) {
		return array;
	}
	const newArray = cloneDeep(array);
	newArray.splice(index, 1);
	return newArray;
};
/**
 * @param array => The array in which to replace the object. The original array is not modified by this method.
 * @param oldObject => The object to remove
 * @param newObject => The object to add at the same index as old
 * @param uniqueProp => A unique property e.g. 'id' that can be used to find the object more efficiently especially in arrays containing large objects
 * @return If object found, returns a new array with the replaced object
 */
export const replaceObjectInArray = (array: Array<any>, oldObject: any, newObject: any, uniqueProp?: string): Array<any> => {
	const index = findObjectIndex(array, oldObject, uniqueProp);
	if (index === -1) {
		return array;
	}
	const newArray = cloneDeep(array);
	newArray.splice(index, 1, newObject);
	return newArray;
};
/**
 *
 * @param array => The array in which to find the object. The original array is not modified by this method.
 * @param object => The object to find
 * @param uniqueProp => A unique property e.g. 'id' that can be used to find the object more efficiently especially in arrays containing large objects
 * @return returns the index of the object or -1 if not found
 */
export const findObjectIndex = (array: Array<any>, object: any, uniqueProp?: string): number => {
	return uniqueProp ? array.findIndex(o => JSON.stringify(o[uniqueProp]) === JSON.stringify(object[uniqueProp]))
		: array.findIndex(o => JSON.stringify(o) === JSON.stringify(object));
};
